/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/leaflet.fullscreen@1.4.5/Control.FullScreen.min.js
 * - /npm/leaflet-loading@0.1.24/src/Control.Loading.min.js
 * - /npm/leaflet.locatecontrol@0.65.2/dist/L.Control.Locate.min.js
 * - /npm/leaflet.markercluster@1.4.1/dist/leaflet.markercluster.min.js
 * - /npm/moment@2.23.0/moment.min.js
 * - /npm/jquery@3.3.1/dist/jquery.min.js
 * - /npm/leaflet-sidebar-v2@3.0.4/js/leaflet-sidebar.min.js
 * - /npm/spin.js@2.3.2/spin.min.js
 * - /npm/focus-visible@4.1.5/dist/focus-visible.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
